﻿/**
 *  @ngdoc controller
 *  @name Reset Password Controller
 *  @description Reset Password controller
 * <h2>Dependencies</h2>
 * "$scope", "resetPasswordResource","toaster", "$stateParams","$location"
 */
(function () {
    "use strict";
    angular.module("myApp")
        .controller("ChangePasswordEmailCtrl", ["$scope", "resetPasswordResource", "toaster", "$stateParams", "$location", "$rootScope","$localStorage", ChangePasswordEmailCtrl]);
    function ChangePasswordEmailCtrl($scope, resetPasswordResource, toaster, $stateParams, location, $rootScope, $localStorage) {

        var resetPass = this;
        resetPass.errorMessage = "";
        $scope.messageError = false;
        resetPass.changePasswordModel = {};
        resetPass.toState = $rootScope.toState.name; 
        resetPass.errorMessage = "";
        resetPass.showErrorMessage = false;
        resetPass.resetPasswordModel = {};//userName
        resetPass.resetPasswordModel.userName = $stateParams.email ? $stateParams.email : $localStorage.userDetails.userName;
        resetPass.resetPasswordModel.code = $stateParams.code;

        $scope.loader = false;
        $scope.messageError = false;

        /**
        * @ngdoc ResetPasswordController
        * @name reset
        * @methodOf Reset Password Controller
        * @param {boolean} valid valid as bool type
        * @description reset password
        * @author vinay, shwetha
        * @returns void
        */
        $scope.change = function (valid) {
            var model = resetPass.resetPasswordModel;
            if (!angular.equals({}, model)) {
                resetPasswordResource.changePasswordEmail(model, function (response) {
                    $scope.loader = false;
                    toaster.success("Your password has been reset successfully. ");
                    $scope.messageError = true;
                    location.path('/login');
                },
                    function (err) {
                        $scope.loader = false;
                        $scope.messageError = true;
                        resetPass.errorMessage = err.data.message;
                    })
            }
        };
        //added by shwetha.
        $scope.enableSubmit = function () {
            resetPass.errorMessage = "";
            $scope.messageError = false;
        }
    }
}())